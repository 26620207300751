
















































import { Component } from 'vue-property-decorator';
import { BContainer } from 'bootstrap-vue';
import SettingsBoxItem from '@/views/settings/SettingsBoxItem.vue';
import UnblockUserModalComponent
  from '@/components/settings/privacy-security/UnblockUserModalComponent.vue';
import ProfileVisibilityModalComponent
  from '@/components/settings/privacy-security/ProfileVisibilityModalComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ProfilePrivacyType from '@/utils/enums/ProfilePrivacyType';
import { Getter } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    UnblockUserModalComponent,
    ProfileVisibilityModalComponent,
    SettingsBoxItem,
    BContainer,
    FontAwesomeComponent,
  },
  beforeRouteEnter(from, to, next) {
    next((vm) => {
      if (!((vm as PrivacySection).hasProfileVisibilityFeature
        || (vm as PrivacySection).hasBlockedUsersFeature)) {
        vm.$router.replace({ name: 'not-found' });
      }
    });
  },
})
export default class PrivacySection extends BreakpointWrapper {
  @Getter
  private authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private FeatureKeys = FeatureKeys;

  private privacySubtitle: TranslateResult | string = '';

  private privacyIcon = 'fa-regular fa-earth-americas';

  private privacyText: TranslateResult | string = '';

  private get hasBlockedUsersFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_ENABLE_BLOCKED_USERS);
  }

  private get hasProfileVisibilityFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_ENABLE_PROFILE_VISIBILITY);
  }

  onEditBlockedUsers(): void {
    this.$bvModal.show('unblock-users-modal');
  }

  onEditProfileVisibility(): void {
    this.$bvModal.show('profile-visibility-modal');
  }

  mounted(): void {
    this.setPrivacySettings();
  }

  setPrivacySettings(privacy?: string): void {
    const privacyMode = privacy || this.authUser.profilePrivacyMode;
    switch (privacyMode) {
      case ProfilePrivacyType.PUBLIC:
        this.privacySubtitle = this.$t('settings-page.privacy-section.visibility-box.subtitle.public-text');
        this.privacyText = this.$t('settings-page.privacy-section.visibility-box.subtitle.public');
        this.privacyIcon = 'fa-regular fa-earth-americas';
        break;
      case ProfilePrivacyType.PRIVATE:
        this.privacySubtitle = this.$t('settings-page.privacy-section.visibility-box.subtitle.private-text');
        this.privacyText = this.$t('settings-page.privacy-section.visibility-box.subtitle.private');
        this.privacyIcon = 'fa-regular fa-lock';
        break;
      case ProfilePrivacyType.CONNECTIONS:
        this.privacySubtitle = this.$t('settings-page.privacy-section.visibility-box.subtitle.connections-text');
        this.privacyText = this.$t('settings-page.privacy-section.visibility-box.subtitle.connections');
        this.privacyIcon = 'fa-regular fa-user-group';
        break;
      default:
        this.privacySubtitle = '';
        break;
    }
  }

  private isFeatureActivated(code: FeatureKeys): boolean {
    return this.featureByKey(code) && this.featureByKey(code).enabled;
  }
}
